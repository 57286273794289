.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  font-size: 190px;
  margin-top: 100px;
  text-align: center;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.attendanceDate {
  display: flex;
  justify-content: space-around;
  /* left: 0%; */
  /* right: 0%; */
  margin-top: 40px;
  /* bottom: 80.38%; */
  height: 70px;
  /* blue */

  background: linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);
  box-shadow: 0px 2.24402px 4.48804px rgba(0, 0, 0, 0.25);

} 

.btncom{
  padding: 5px;
  font-Weight: "bold" 
}
.btn-attendance {
  color: #fff !important;
  background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
  border-radius: 11.2201px;
}
@media screen and (min-width: 1050px) {
  .attendance1{
   
width: 90%;
height: 161.49px;
margin-top: 1%;


/* blue */

background: linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);
box-shadow: 0px 0px 6.87196px rgba(0, 0, 0, 0.25)
  }
 
}
