 *{
    margin: 0;
    padding: 0;
 }
 
 @media (max-width:600px) {
    .display{
        display: none!important;
    }
    .displayu{
        display: none!important;
    }
 }
 @media (max-width:1100px) {
    .secondimg{
        width: 363px;
    }
    .firstimg{
        width:239px ;
    }
 }
 @media (max-width:937px) {
    .secondimg{
        width: 335px;
    }
    .firstimg{
        width:218px ;
    }
 }
 @media (max-width:850px) {
    .secondimg{
        width: 300px;
    }
    .firstimg{
        width:192px ;
    }
 }
 @media (max-width:770px) {
    .secondimg{
        width: 275px;
    }
    .firstimg{
        width:175px ;
    }
 }
 @media (max-width:674px) {
    .secondimg{
        width: 250px;
    }
    .firstimg{
        width:163px ;
    }
 }


.displayu{
    background: rgb(239, 254, 254);
    height: 100vh!important;
    width: 100vw!important;
   
    /* #f5fbfb,#EBFBFF */
}



.displayu{
    display: flex;
    justify-content: center;
    align-items: center;
}
.display{
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    align-items: center;
    background-image: url(/src/pages/image/bg.png);
    height: 100vh!important;
    width: 100vw!important;
}