.head{
    position: absolute;
    width: 100%;
    height: 51.52px;
    left: 0px;
    top: 0px;
    
    background: linear-gradient(91.75deg, #2B2971 0.93%, #158FC5 89.74%, #10A7D9 110.51%);
  }
  .orderstatus{
    /* position: absolute; */
/* width: 386.1px; */
/* height: 54px; */
/* left: 48px; */
/* top: 207px; */

font-family: 'Montserrat'!important;
/* font-style: normal; */
font-weight: 700 !important;
font-size: 46px !important;
/* line-height: 57px; */
/* text-transform: uppercase; */

background: linear-gradient(90.6deg, #2B2B73 0.99%, #1497CC 96.36%);
/* -webkit-background-clip: text; */
-webkit-text-fill-color: transparent;
background-clip: text;
/* text-fill-color: transparent; */

  }
  .rtosts{
    position: absolute;
width: 90%;
height: 228.47px;
left:5%;

background: rgba(242, 242, 242, 0.6);
  }