.colorname {
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
    /* margin-left: 50px */
    width: 20%;
    height: 5vh;
    margin-left: 4%;
    /* flex-direction: row; */
    /* display: flex; */
    /* justify-content: space-between; */
}

.coloradd {
    /* position: absolute; */
    width: 150px;
    /* height: 48px; */
    /* left: 46px; */
    right: 86px;
    color: white;
    /* top: 1007px; */

    /* background: #DCE4E5; */
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);

    border-radius: 800px !important;
}
.btns{
    /* position: absolute; */
    /* width: 150px; */
    /* height: 48px; */
    /* left: 46px; */
    /* right: 86px; */
    color: rgb(248, 248, 248);
    /* top: 1007px; */

    /* background: #DCE4E5; */
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);

    border-radius:5px !important;
}

.colorhead {
    box-sizing: border-box;


    width: 1357px;


    background: linear-gradient(91.75deg, #D9D9D9 0.93%, #FFFFFF 110.51%);
    border: 0.830269px solid #4D4949;
}
.colorbody{
    
box-sizing: border-box;

/* position: absolute; */
width: 1346px;
height: 21px;


/* background: #DCE4E5; */
background: 
linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);

border: 0.830269px solid #C0ADAD;
}