.demo{
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
  height: 60px;
} 
  .order{ background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
    linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 70px; 

  }
  .orderdash{ background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
    linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 40px; 

  }
    
  .pay{
  
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 60px;

}
.reg{
  
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 60px;

}
.vehicle{
  
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 60px;

}
.allot{
  
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 60px;

}
.atten{
  
    background: linear-gradient(91.75deg, #2B2D74 0.93%, #1789C0 110.51%),
    linear-gradient(120.2deg, #F4F4F4 14.07%, rgba(192, 192, 192, 0.528125) 37.92%, rgba(97, 97, 97, 0.395836) 61.29%, rgba(35, 35, 35, 0.22) 92.23%);
     height: 40px;   
      

}
.num{
  
    background: linear-gradient(91.75deg, #2B3278 0.93%, #1C77B1 42.02%, #FFFFFF 62.57%),
linear-gradient(120.2deg, rgba(192, 192, 192, 0.528125) 14.07%, rgba(244, 244, 244, 0.62) 23.05%, rgba(97, 97, 97, 0.395836) 52.52%, rgba(35, 35, 35, 0.22) 71.04%);
    height: 60px;

}
.button{
    background: linear-gradient(91.75deg, #2A367C 0.93%, #1C75B0 110.51%);

}
.firstcard{
    /* background: linear-gradient(120.2deg, #F4F4F4 14.07%, rgb(255, 255, 255) 37.92%, rgba(255, 255, 255, 0.933) 61.29%, rgba(35, 35, 35, 0.22) 92.23%); */
    background: #FFFFFF;

}

    
    
    
   