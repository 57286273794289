.view{
    /* Rectangle 577 */


/* box-sizing: border-box; */

/* position: absolute; */
width: 100%;
/* height: 100%; */
/* left: -600px; */
/* overflow-y: hidden !important; */
/* overflow: hidden !important; */
/* overflow-x: hidden !important; */
/* top: 213px; */
/* transform: 'translate(-50%, -50%)'; */

background: #DCE4E5;
border: 0.830269px solid #C0ADAD;
}
.views{
    /* Rectangle 577 */


/* box-sizing: border-box; */

/* position: absolute; */
width: 100%;
/* height: 100%; */
/* left: -600px; */
/* overflow-y: hidden !important; */
/* overflow: hidden !important; */
/* overflow-x: hidden !important; */
/* top: 213px; */
/* transform: 'translate(-50%, -50%)'; */

/* background: #DCE4E5; */
/* border: 0.830269px solid #C0ADAD; */
}
.details{
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
    /* margin-left: 50px */
    width: 30%;
    height: 5vh;
    margin-top: 2vh;
    margin-left: -1%;
}