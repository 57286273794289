* {
  padding: 0;
  margin: 0;
}

.bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),url(../img/bg.jpg);
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
