.ordername {
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
    /* margin-left: 50px */
    width: 20%;
    height: 5vh;
    margin-left: 4%;
    /* flex-direction: row; */
    /* display: flex; */
    /* justify-content: space-between; */
}
.ordernames {
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
    /* margin-left: -800px;
    mar */
    margin-right: 85px;
    width: 20%;
    height: 5vh;
    border-radius: 100px !important;
    /* margin-left: 1%; */
    /* flex-direction: row; */
    /* display: flex; */
    /* justify-content: space-between; */
}
.makeorder {
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);
    /* margin-left: 50px */
    width: 25%;
    height: 5vh;
    margin-left: -5%;
    /* flex-direction: row; */
    /* display: flex; */
    /* justify-content: space-between; */
}

.orderadd {
    /* position: absolute; */
    width: 150px;
    /* height: 48px; */
    /* left: 46px; */
    right: 86px;
    color: white;
    /* top: 1007px; */

    /* background: #DCE4E5; */
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);

    border-radius: 800px !important;
}

.orderadd:hover {


    background: linear-gradient(91.75deg, #10A7D9 0.93%, #2B2971 110.51%);

    transition: 0.2s !important;
    cursor: pointer;
}

.orderhead {
    box-sizing: border-box;


    width: 1357px;


    background: linear-gradient(91.75deg, #D9D9D9 0.93%, #FFFFFF 110.51%);
    border: 0.830269px solid #4D4949;
}

.orderheadattendance {
  box-sizing: border-box;
font-size: 2px !important;

  width: 1357px;


  background: #F8F8F8;
  border: none;
}
.orderbody {

    box-sizing: border-box;

    /* position: absolute; */
    width: 1346px;
    height: 21px;


    /* background: #DCE4E5; */
    background:
        linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);

    border: 0.830269px solid #C0ADAD;
}
.orderbodys{

    /* box-sizing: border-box; */

    /* position: absolute; */
    /* width: 1346px; */
    /* height: 21px; */


    /* background: #DCE4E5; */
    background:
        linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);

    border: 0.830269px solid #C0ADAD;
}
.detail {
  /* position: absolute; */
  width:200px;
 margin-top: 20px;
  /* height: 48px; */
  /* left: 46px; */
  /* right: 86px; */
  color: white;
  /* top: 1007px; */

  /* background: #DCE4E5; */
  background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);



  /* border-radius: 80px !important; */
  /* cursor: pointer; */

}

.delete {
    /* position: absolute; */
    width: 100px;
    /* height: 48px; */
    /* left: 46px; */
    /* right: 86px; */
    color: white;
    /* top: 1007px; */

    /* background: #DCE4E5; */
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);



    border-radius: 800px !important;
    cursor: pointer;

}

.delete:hover {
    /* background-color: darkred !important; */
    /* background: palegreen; */
    background: linear-gradient(91.75deg, #10A7D9 0.93%, #2B2971 110.51%);

    transition: 0.2s !important;
    cursor: pointer;
}

.cancel {
    /* position: absolute; */
    width: 150px;
    /* height: 48px; */
    /* left: 46px; */
    /* right: 86px; */
    color: white;
    /* top: 1007px; */

    /* background: #DCE4E5; */
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%);

    border-radius: 800px !important;
}
.bodysts{
  align-items: center !important;

}

.status{
  /* position: absolute; */
/* width: 1080px; */
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
/* background: linear-gradient(91.75deg, #605f78 0.93%, #10A7D9 110.51%); */
background: #e7edee;
/* opacity: 0.5; */
/* box-shadow: 3px 0px 19px 10px rgba(0, 0, 0, 0.25); */
/* transform: matrix(1, 0, 0, -1, 0, 0); */
/* height: 654px; */
/* left: 1020px !important; */
margin-left: 30%;
margin-top: 5%;


/* top: 213px; */

}
.dashbord{
  margin-top: -0.5%;
}
@media only screen and (max-width: 1800px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -142px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -20px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1740px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -133px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1720px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -123px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1700px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -123px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1720px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -113px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1675px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -103px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1660px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -93px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1640px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -83px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1617px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -73px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
  
}
@media only screen and (max-width: 1610px) {
  .countdash {
    margin-left: 18px !important;
    width: 340px !important;
    
  }
  .topbutton {
    margin-left: 258px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 440px !important;
    
  }
  .noplatedash {
    margin-left: 38px !important;
    width: 500px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: 38px !important;
    
  }
  .dashquatation {
    margin-left: -50px !important;
    width: 460px !important;
    
  }
  .dashmanufact{
    margin-left: -8px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: 20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 30px !important;
    width: 365px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 340px !important;

  }
  
}
/* @media only screen and (max-width: 1610px) {
  .dashquatation {
    margin-left: -108px !important;
    
  }
} */
@media only screen and (max-width: 1590px) {
  .dashquatation {
    margin-left: -50px !important;
    
  }
}
@media only screen and (max-width: 1560px) {
  .dashquatation {
    margin-left: -48px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .dashvechservice{
    margin-left: 15px !important;
    width: 365px !important;

  }
  .dashbord {
    margin-left: -30px !important;
    
  }
}
@media only screen and (max-width: 1530px) {
  .dashquatation {
    margin-left: -48px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 450px !important;

  }
  .dashvechservice{
    margin-left: -0px !important;
    width: 395px !important;

  }
  .dashbord {
    margin-left: -30px !important;
    
  }
  .allote{
    margin-left: -10px !important;
    width: 420px !important;
    

  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 440px !important;
    
  }
  .noplatedash {
    margin-left: -0px !important;
    width: 500px !important;
    
  }
  .dashatten{
    margin-left: -0px !important;
    width: 340px !important;

  }
}

/* @media only screen and (max-width: 1530px) {
  .dashquatation {
    margin-left: -98px !important;
    
  }
} */
@media only screen and (max-width: 1500px) {
  .dashquatation {
    margin-left: -48px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 450px !important;

  }
  .dashvechservice{
    margin-left: -0px !important;
    width: 395px !important;

  }
  .dashbord {
    margin-left: -30px !important;
    
  }
  .allote{
    margin-left: -10px !important;
    width: 420px !important;
    

  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 440px !important;
    
  }
  .noplatedash {
    margin-left: -0px !important;
    width: 500px !important;
    
  }
  .dashatten{
    margin-left: -0px !important;
    width: 340px !important;

  }
}

@media only screen and (max-width: 1490px) {
  .countdash {
    margin-left: 18px !important;
    width: 300px !important;
    
  }
  .topbutton {
    margin-left: 208px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 18px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: -20px !important;
    
  }
  .dashquatation {
    margin-left: -43px !important;
    width: 440px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: -10px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: 0px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: 20px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1440px) {
  .countdash {
    margin-left: 18px !important;
    width: 300px !important;
    
  }
  .topbutton {
    margin-left: 180px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 18px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .dashbord {
    margin-left: -30px !important;
    
  }
  .dashquatation {
    margin-left: -43px !important;
    width: 440px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 460px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 420px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -10px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1420px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 180px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .dashbord {
    margin-left: -30px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 420px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 440px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 400px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1412px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 140px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 420px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .dashbord {
    margin-left: -30px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 420px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 440px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 400px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1400px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 140px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 420px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 380px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1375px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 120px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 400px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 440px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 380px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 320px !important;

  }
}
@media only screen and (max-width: 1360px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 100px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 440px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 370px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 310px !important;

  }
}
@media only screen and (max-width: 1340px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 100px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 420px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 370px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 360px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 310px !important;

  }
}
@media only screen and (max-width: 1332px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 60px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 400px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 370px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 335px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 310px !important;

  }
}
@media only screen and (max-width: 1330px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 80px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 400px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 370px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 335px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 310px !important;

  }
}
@media only screen and (max-width: 1315px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 60px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 400px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 370px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 335px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 290px !important;

  }
}
@media only screen and (max-width: 1292px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 40px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 360px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 345Px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 335px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 280px !important;

  }
}
@media only screen and (max-width: 1280px) {
  .countdash {
    margin-left: 18px !important;
    width: 280px !important;
    
  }
  .topbutton {
    margin-left: 40px !important;
    
  }
  .regdash {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .noplatedash {
    margin-left: 0px !important;
    width: 450px !important;
    
  }
  .vechdashpending {
    /* margin-left: 38px !important; */
    width: 380px !important;
    
  }
  .dashbord {
    margin-left: -40px !important;
    
  }
  .dashquatation {
    margin-left: -53px !important;
    width: 400px !important;
    
  }
  .dashmanufact{
    margin-left: -18px !important;
    width: 360px !important;

  }
  .allote{
    margin-left: -20px !important;
    width: 345Px !important;
    

  }
  .dashvechservice{
    margin-left: -10px !important;
    width: 335px !important;

  }
  .dashatten{
    margin-left: -15px !important;
    width: 270px !important;

  }
}


/* @media only screen and (max-width: 1400px) {
  .dashquatation {
    margin-left: -48px !important;
    
  }
} */








@media only screen and (max-width: 920px) {
  .status {
    /* background-color: lightblue; */
    width: 45% !important;

  }
}
@media only screen and (max-width: 725px) {
  .status {
    /* background-color: lightblue; */
    width: 55% !important;

  }
}
@media only screen and (max-width: 590px) {
  .status {
    /* background-color: lightblue; */
    width: 65% !important;
    margin-left: 15%;

  }
}
@media only screen and (max-width:484px) {
  .status {
    /* background-color: lightblue; */
    width: 75% !important;
    margin-left: 10%;

  }
}
@media only screen and (min-width:393px) {
  .status {
    /* background-color: lightblue; */
    width: 85% !important;
    margin-left: 10%;

  }
}
@media only screen and (min-width:375px) {
  .status {
    /* background-color: lightblue; */
    /* width: 90% !important; */
    margin-left: 5%;

  }
  .servicetype1{
    width: 300px !important;
    
  }
  .inputs{
    /* width: 300px !important; */
    margin-left: 8%;

  }
}
@media only screen and (min-width:360px) {
  .status {
    /* background-color: lightblue; */
    width: 90% !important;
    margin-left: 5%;

  }
}
@media only screen and (max-width: 1352px) {
    .dashbord {
      /* background-color: lightblue; */
      /* margin-left: 2%; */
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: hidden;
      width: 110%;
    }
  }

@media only screen and (min-width: 1450px) {
    .dashbord {
      /* background-color: lightblue; */
      /* margin-left: 3%; */
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: hidden;
    }
  }
@media only screen and (min-width: 1470px) {
    .dashbord {
      /* background-color: lightblue; */
      /* margin-left: 6%; */
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: hidden;
      /* width: 100%; */
    }
  }
@media only screen and (min-width: 1490px) {
    .dashbord {
      /* background-color: lightblue; */
      /* margin-left: 1%; */
      /* overflow-x: hidden; */
      overflow-y: hidden;
      overflow-x: hidden;
      overflow: hidden;
      /* width: 100%; */
    }
  }
  .employeed{
    background-color: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%) ;

  }
  .track{
    background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%) ;
    ;
  }
 .submit{
  background: linear-gradient(91.75deg, #2B2971 0.93%, #10A7D9 110.51%) ;
 }
 .alert-dialog-title{
  background:   #DCE4E5;
 }
